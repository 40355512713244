exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-shopdetails-1-js": () => import("./../../../src/pages/shopdetails1.js" /* webpackChunkName: "component---src-pages-shopdetails-1-js" */),
  "component---src-pages-shopdetails-2-js": () => import("./../../../src/pages/shopdetails2.js" /* webpackChunkName: "component---src-pages-shopdetails-2-js" */),
  "component---src-pages-shopdetails-3-js": () => import("./../../../src/pages/shopdetails3.js" /* webpackChunkName: "component---src-pages-shopdetails-3-js" */),
  "component---src-pages-shopdetails-5-js": () => import("./../../../src/pages/shopdetails5.js" /* webpackChunkName: "component---src-pages-shopdetails-5-js" */)
}

